import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import HomeView from '@/views/HomeView.vue';
import BlankView from '@/views/BlankView.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/index_sp.html',
    name: 'home_sp',
    component: HomeView
  },
  {
    path: '/form1.html',
    name: 'form1',
    component: BlankView
  },
  {
    path: '/form2.html',
    name: 'form2',
    component: BlankView
  },
  {
    path: '/form3.html',
    name: 'form3',
    component: BlankView
  },
  {
    path: '/form4.html',
    name: 'form4',
    component: BlankView
  },
  {
    path: '/form5.html',
    name: 'form5',
    component: BlankView
  },
  {
    path: '/mypage.html',
    name: 'mypage',
    component: BlankView
  },
  {
    path: '/mypage_top.html',
    name: 'mypage_top',
    component: BlankView
  },
  {
    path: '/mypage_password.html',
    name: 'mypage_password',
    component: BlankView
  },
  {
    path: '/mypage_password_send.html',
    name: 'mypage_password_send',
    component: BlankView
  },
  {
    path: '/mypage_logout.html',
    name: 'mypage_logout',
    component: BlankView
  },
  {
    path: '/mypage_allcancel.html',
    name: 'mypage_allcancel',
    component: BlankView
  },
  {
    path: '/mypage_password_send.html',
    name: 'mypage_password_send',
    component: BlankView
  },
  {
    path: '/_filelist.html',
    name: '_filelist',
    component: BlankView
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
